<template>
  <button :class="`ButtonSocial ButtonSocial--${size}`" :disabled="disabled" :type="type">
    <div class="ButtonSocial__icon" :class="`ButtonSocial__icon--${size}`">
      <component :is="icons[social][size]" />
    </div>
  </button>
</template>

<script>
import Tg from '@/assets/svg/tgSocialLg.svg'
import Ok from '@/assets/svg/okSocialLg.svg'
import Vk from '@/assets/svg/vkSocialLg.svg'
import Yandex from '@/assets/svg/yaSocialLg.svg'
import Tgsm from '@/assets/svg/tgSocialSm.svg'
import Zensm from '@/assets/svg/zenSocialSm.svg'
import Youtubesm from '@/assets/svg/youtubeSocialSm.svg'
import Vksm from '@/assets/svg/vkSocialSm.svg'

export default {
  name: 'ButtonSocial',
  components: {
    Tg,
    Ok,
    Vk,
    Yandex,
    Tgsm,
    Zensm,
    Youtubesm,
    Vksm
  },
  props: {
    social: {
      type: String,
      validator (value) {
        return ['telegram', 'ok', 'vk', 'yandex', 'youtube', 'zen'].includes(value)
      },
      required: true
    },
    size: {
      type: String,
      validator (value) {
        return ['lg', 'sm'].includes(value)
      },
      default: 'lg'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button',
      validator (value) {
        return ['button', 'submit', 'reset'].includes(value)
      }
    }
  },
  data() {
    return {
      icons: {
        telegram: { lg: shallowRef(Tg), sm: shallowRef(Tgsm) },
        ok: { lg: shallowRef(Ok), sm: null },
        vk: { lg: shallowRef(Vk), sm: shallowRef(Vksm) },
        yandex: { lg: shallowRef(Yandex), sm: null },
        youtube: { lg: null, sm: shallowRef(Youtubesm) },
        zen: { lg: null, sm: shallowRef(Zensm) }
      }
    }
  },
  methods: {
    getIcon(social, size) {
      return this.icons[social][size];
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  cursor: pointer;
}

.ButtonSocial__icon {
  margin-left: auto;
  margin-right: auto;

  &:deep(svg [clip-path]) {
    clip-path: none;
  }
}

.ButtonSocial--lg {
  display: flex;
  border-radius: get-vw(18);
  border: none;
  background-color: $base-25;
  padding: get-vw(21) get-vw(32);
  &:hover {
    background-color: $base-50;
  }
  &:active {
    background-color: $base-100;
  }
}
.ButtonSocial__icon--lg {
  width: get-vw(36);
  height: get-vw(36);
  display: flex;
  align-items: center;
  &:deep(svg) {
    width: 100%;
    height: 100%;
    }
}
.ButtonSocial--sm {
  display: flex;
  border-radius: get-vw(12);
  border: none;
  background-color: $black;
  padding: get-vw(8);
  &:hover {
    background-color: $black-hover;
  }
  &:active {
    background-color: $black-pressed;
  }
}
.ButtonSocial__icon--sm {
  width: get-vw(28);
  height: get-vw(28);
  display: flex;
  align-items: center;
  &:deep(svg) {
    width: 100%;
    height: 100%;
    }
  &:deep(svg [clip-path]) {
    clip-path: none;
  }
}

@include tablet {
  .ButtonSocial--lg {
    display: flex;
    border-radius: get-vw(9, "md");
    padding: get-vw(11.5, "md") get-vw(18.5, "md");
  }
  .ButtonSocial__icon--lg {
    width: get-vw(18, "md");
    height: get-vw(18, "md");
  }
  .ButtonSocial--sm {
    border-radius: get-vw(6, "md");
    padding: get-vw(4, "md");
}
.ButtonSocial__icon--sm {
  width: get-vw(16, "md");
  height: get-vw(16, "md");
  }
}

@include mobile {
  .ButtonSocial--lg {
    display: flex;
    border-radius: get-vw(9, "sm");
    padding: get-vw(11.5, "sm") get-vw(18.5, "sm");
  }
  .ButtonSocial__icon--lg {
    width: get-vw(18, "sm");
    height: get-vw(18, "sm");
  }
  .ButtonSocial--sm {
    border-radius: get-vw(6, "sm");
    padding: get-vw(4, "sm");
}
.ButtonSocial__icon--sm {
  width: get-vw(16, "sm");
  height: get-vw(16, "sm");
  }
}
</style>
